<template>
  <div class="journalism-container">
    <div class="journalism-top" style="position: relative">
      <input class="input" placeholder="搜索关键字" v-model="waitSearch" />
      <img src="../../assets/images/xiaochazi.png" alt="" style="width: 18px;left: 300px;top:10px;position: absolute" @click="clearInput" v-show="waitSearch !== ''">
      <button class="btn" @click="waitSelectBtn" style="vertical-align: top">查询</button>
    </div>
    <div v-if="noticeList !== null && noticeList.length>0">
      <div class="journalism-con" >
        <el-container
                class="journa-item"
                v-for="item in noticeList"
                :key="item.id"
                style="cursor:pointer"
                @click="clickHref(item.contentUrl)"
        >

          <el-aside width="250px">
            <div class="journalism-container-left">
              <img :src="item.picUrl" />
              <div style="text-align: left;padding-left: 10px;font-size:18px">
                {{ item.updateTime }}
              </div>
            </div>
          </el-aside>
          <el-main style="padding: 0 0 0 30px" >
            <div class="title-text">{{ item.title }}</div>
            <div class="title-tep">
              <a :href="item.contentUrl" target="_blank" style="color: #555">
                {{ item.content }}
              </a>
            </div>
          </el-main>
        </el-container>
      </div>
      <div class="demo-pagination-block">
        <Pagination :total="total" :page="pageNo" :pageSize='6' :showPage="6" key="journalism" name = "journalism" v-show="noticeList !== null && noticeList.length > 0" />
      </div>
    </div>
    <div v-else class="no-date">
      暂无相关内容
    </div>
  </div>
</template>

<script>
import { ref, onMounted,onBeforeUnmount,nextTick } from "vue";
import { fly, queryWebsiteContentAll } from "../../requestUrl";
import Pagination from "@/components/Pagination.vue";
import mitt from "@/utils/mitt";
export default {
  setup() {
    const pageSize = ref(6);
    const pageNo = ref(1);
    const total = ref(9999999999999);
    const noticeSearch = ref("");
    const waitSearch = ref("");
    const noticeList = ref([]);
    const selectBtn = () => {
      fly
        .post(queryWebsiteContentAll, {
          regionCode:localStorage.getItem('regionCode'),
          title: noticeSearch.value,
          pageSize: pageSize.value, //默认10
          pageNum: pageNo.value, //为空查全部
          type: 1,
        })
        .then((res) => {
          noticeList.value = res.data.data.datas;
          total.value = res.data.data.total;
        });
    };
    const queryBt = (page) => {
        console.log(3);
        pageNo.value = page;
        selectBtn();
      }
    onBeforeUnmount(() => {

      mitt.off('Journalism',queryBt );
      const div = document.getElementsByClassName("realimeInfo-bottom")[0];
      const height = div.clientHeight;
      div.style.height = height + "px";
      nextTick(() => {
        div.style.height = "auto";
      });
    });
    const clearInput = ()=>{
      waitSearch.value= '';
      noticeSearch.value = '';
    }
    const waitSelectBtn = () =>{
      noticeSearch.value = waitSearch.value;
      selectBtn();
    };
    selectBtn();
    onMounted(() => {
      const hash = location.hash.replace(/#\/?/, "");
      mitt.on(hash, queryBt);
    });
    const clickHref = (href) =>{
      // window.location.href=href;
      window.open(href)
    };
    return {
      noticeSearch,
      selectBtn,
      total,
      pageNo,
      noticeList,
      waitSearch,
      waitSelectBtn,
      clearInput,
      clickHref
    };
  },
  components: {
    Pagination,
  },
};
</script>

<style scoped>
.journalism-container-left {
  overflow: hidden;
  position: relative;
}
.journalism-container-left img {
  width: 250px;
  height: 130px;
}
.journalism-container-left div {
  width: 250px;
  height: 30px;
  background: rgba(0, 0, 0, 0.39);
  opacity: 1;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 3px;
}
.title-tep {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #555;
  margin-top: 20px;
}
.journa-item {
  margin-top: 20px;
}.demo-pagination-block{

  max-width: 1220px;
}

@media screen and (max-width:1470px) {
  .title-text{
    margin-left: 0;
  }
}
</style>
